import { Http } from '@zhfe/common-library';
import { Injectable } from '@tanbo/vue-di-plugin'
import { Subject } from 'rxjs'
import { BridgeService } from './bridge.service';
import { CookieService } from './cookie.service';
import { ConfigService } from '@/services/config.service';
import { CommonService } from './common.service';

/** 通用的可牛会员类型 */
export enum KENIU_VIP_TYPE {
  /** 可牛普通 */
  normal = 'keniu_normal',
  /** 可牛钻石 */
  diamond = 'keniu_diamond'
}

export interface WxInfo {
  /** 用户的对应wx公众号 open_id(仅微信用户返回) */
  wx_web_open_id: string
}
export interface UserInfo {
  /** 用户的uid */
  open_id: string
  /** 用户唯一标识 */
  token: string
  /** 登录类型（0:未知 1:微信 2:QQ 3:手机） */
  login_type: number
  /** 头像url */
  avatar?: string
  /** 用户名 */
  nickname?: string
  /** 微信信息 */
  wx_info?: WxInfo
}

export interface PageDetail {
  /** 图标链接 */
  img_url?: string
  /** 是否永久会员 */
  is_diamond?: string
  /** 名字 */
  name?: string
}

export interface VipInfoList {
  /** vip的类型 */
  vip_type: string
  /** 有效期 */
  expired_at: string
  /** 是否签约购买(TODO: 要考虑下如何获取) */
  is_continuous: boolean
  /** 展示的相关信息 */
  page_detail: PageDetail
}

export interface PermissionList {
  /** 权益类型 */
  permission_type: string
  /** 有效期 */
  expired_at: number
}

export interface UserInfoResponseData {
  user_info: UserInfo
  /** 用户有效的权益信息列表 */
  permission_list?: PermissionList[]
  /** vip信息（有vip才会买） */
  vip_info_list?: VipInfoList[]
}

export interface longUserInfoParams {
  /** 用户的唯一令牌 */
  open_id: string
  /** 用户唯一标识 */
  token: string
  userInfo?: UserInfoResponseData
}

@Injectable()
export class UserService {
  onUserInfoChanged = new Subject<void>() // 用户信息改变事件（登录，支付 等触发场景）
  onLogout = new Subject<void>() // 退出改变事件（退出登录 等触发场景）

  userInfo = {} as UserInfoResponseData;

  constructor(
    private http: Http,
    private bridge: BridgeService,
    private cookie: CookieService,
    private config: ConfigService,
    private common: CommonService
  ) {
    if (this.isLogin) {
      this.requestUserInfo().then(() => {
        this.updateBridgeNeedUserParams()
      })
    }

    this.onLogoutSubscribe()
  }

  /** 是否登录 */
  get isLogin(): boolean {
    return this.cookie.isLogin
  }

  /** 用户登录类型 */
  get loignType(): number {
    return this.userInfo?.user_info?.login_type
  }

  /** 是否为会员 */
  get isVip(): boolean {
    return this.isDiamondVIP || this.normalVIP
  }

  /** 普通会员 */
  get normalVIP(): boolean {
    const normalInfo = [this.config.getMemberTypeInfo.normal]

    return normalInfo.includes(this.getPriorityMemberInfo?.vip_type)
  }

  /** 钻石会员 */
  get isDiamondVIP(): boolean {
    const diamondInfo = [this.config.getMemberTypeInfo.diamond]

    return diamondInfo.includes(this.getPriorityMemberInfo?.vip_type)
  }

  /** 续费用户 */
  get isContinuous(): boolean {
    return !!this.getPriorityMemberInfo?.is_continuous
  }

  /** 会员有效期时间戳 */
  get memberExpiredMs(): number {
    return Number(this.getPriorityMemberInfo?.expired_at) ?? 0
  }

  /** 用户头像 */
  get avatar(): string {
    return this.userInfo?.user_info?.avatar || ''
  }

  /** 用户昵称 */
  get nickname (): string {
    return this.userInfo?.user_info?.nickname || ''
  }

  /** 唯一标识 */
  get token(): string {
    return this.cookie.getToken
  }

  /** 唯一令牌 */
  get openId():string {
    return this.cookie.getOpenId
  }

  /** 会员类型集合 */
  get vip_Info (): VipInfoList[] {
    return this.userInfo?.vip_info_list || []
  }

  /**
   * @description 查看对应会员信息
   */
  private querySomeMemberInfo (arr: string[]): VipInfoList {
    for (const p of arr) {
      for (const c of this.vip_Info) {
        if (c.vip_type === p) {
          return c
        }
      }
    }

    return {} as VipInfoList
  }

  /**
   * @description 根据优先级获取会员信息
   */
  get getPriorityMemberInfo(): VipInfoList {
    const currentProductMemberInfo = this.config.getMemberTypeInfo
    // 会员优先级展示顺序 产品钻石 => 产品普通
    const memberArr = [currentProductMemberInfo.diamond, currentProductMemberInfo.normal]

    for (const p of memberArr) {
      for (const c of this.vip_Info) {
        if (c.vip_type === p) {
          return c
        }
      }
    }

    return {} as VipInfoList
  }

  /**
   * @description 处理时间文案的会员信息
   */
  get formatDateVipInfo () {
    return this.vip_Info.map(e => ({ ...e, expired_at: this.handleMoreMemberDateText(e) })) || []
  }

  /**
   * @description 超级更多会员信息日期文案
   */
  handleMoreMemberDateText (obj: VipInfoList) {
    const is_diamond = obj.page_detail?.is_diamond
    return is_diamond ? '永久' : this.common.handleDate(Number(obj.expired_at))
  }

  /**
   * @description 会员剩余时长文案
   */
  memberDateText(date = ''): string {
    return (this.getPriorityMemberInfo?.page_detail?.is_diamond
      ? '永久'
      : `${this.common.handleDate(Number(date) || this.memberExpiredMs)}到期`)
  }

  /** 更新客户端方法所需用户参数 */
  updateBridgeNeedUserParams () {
    const params = {
      open_id: this.openId,
      token: this.token,
      type: this.loignType,
      noRefresh: 1
    }

    this.bridge.setDefaultUserParams(params)
  }

  /** 订阅退出登录 */
  onLogoutSubscribe () {
    this.onLogout.subscribe(() => {
      this.bridge.exit()
      this.cookie.removeUserInfoLongTerm()
      this.bridge.refreshPage()
    })
  }

  /**
   * @description 请求用户信息
   */
  requestUserInfo(params = {}) {
    return this.http.post<UserInfoResponseData>('/usermix/user_info', params).then((res) => {
      const userInfo = res
      const { token, open_id } = userInfo?.user_info

      this.userInfo = userInfo
      this.cookie.setUserInfoLongTerm({ token, open_id, userInfo })
      this.onUserInfoChanged.next()
    })
  }
}
