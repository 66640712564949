interface IgetProductInfo {
  /** 唯一id */
  appid: string
  /** 令牌 */
  secret: string
  /** 版本 */
  version: number
}

interface IgetMemberTypeInfo {
  /** 普通会员 */
  normal: string
  /** 钻石会员 */
  diamond: string
}

/**
 * 产品id枚举
 * 101被海外项目占用
 */
export enum product_id_info {
  ys = 108 /** 可牛压缩 */
}

/** 登录类型枚举 */
export enum login_type_info {
  wechat = 1, /** 微信 */
  qq = 2, /** QQ */
  phone = 3, /** 手机 */
  fast = 4 /** 快速登录 -- 上报使用 */
}

export const login_type_text_info = {
  [login_type_info.wechat]: '微信',
  [login_type_info.qq]: 'QQ',
  [login_type_info.phone]: '手机'
}

/**
 * @description 配置服务
 * 根据载入不同得配置初始化项目，当前（可牛看图、可牛PDF、可牛电脑助手）都根据配置来初始化，共用一套域名
 * 在未来当前配置无法满足运营需求，则再做项目拆分 （根据product_id做区分）
 */
export abstract class ConfigService {
  /** 渠道号 */
  getTryno: string
  /** 获取logo集合 */
  logoInfo: string
  /** 获取标题集合 */
  getTitleInfo: string
  /** 获取默认套餐id */
  getPackageId: string
  /** 获取区分场景套餐id */
  getScenePackageId: string
  /** 获取会员中心标题 */
  getTitle: string
  /** appid */
  wxAppId: string
  /** logo */
  getLogo: string
  /** 获取公众号登录引导图片 */
  getOfficialLoginImg: string
  /** 获取头部二维码图片 */
  getHeaderQrCodeImg: string
  /** 获取会员协议链接 */
  getMemberProtocolLink: string
  /** 获取产品信息 */
  getProductInfo: IgetProductInfo
  /** 获取会员类型信息 */
  getMemberTypeInfo: IgetMemberTypeInfo
  /** 请求url */
  getRequestUrl: string
}
