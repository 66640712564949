import MyToast from './toast.vue';
import {createVNode, render} from 'vue';

export interface toastParams {
  message:string,
  duration?:number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose?: Function
}

export default {
  install: (app:any):void => {
    let isToast = false;
    app.config.globalProperties.$toast = function(opts:toastParams) {
      if (!isToast) {
        isToast = true
        const vm:any = createVNode(MyToast)
        const container = document.createElement('div')

        render(vm, container);
        document.body.appendChild(container)
        vm.component.props.message = opts.message || ''

        const duration = opts.duration || 2000

        setTimeout(() => {
          document.body.removeChild(container)
          isToast = false

          if (opts.onClose) {
            opts.onClose()
          }
        }, duration)
      }
    }
  }
}
