import { createApp } from 'vue'
import router from './router'
import App from './app.vue'
import Toast from './components/toast/toast'
import 'normalize.css'
import { Provider, reflectiveInjectorPlugin, ReflectiveInjector, NullInjector } from '@tanbo/vue-di-plugin'
import { Http, HttpConfig, HttpInterceptor } from '@zhfe/common-library'
import { HttpInterceptorService } from './services/http-interceptor.service'
import { UserService } from './services/user.service';
import { BridgeService } from './services/bridge.service';
import { CookieService } from './services/cookie.service';
import { ToastService } from '@/services/toast.service';
import { ReportService } from '@/services/report.service';
import {ConfigService, product_id_info } from '@/services/config.service';
import { CommonService } from '@/services/common.service';
import {InterestsService} from '@/views/interests/interests.service';
import {YsService} from '@/services/product/ys.service';
import { LocalStorageService } from '@/services/local-storage.service'

if (process.env.STAGE === 'test' || process.env.NODE_ENV === 'development') {
  const eruda = require('eruda/eruda.js')
  eruda.init()
}

const providers: Provider[] = [
  Http,
  UserService,
  BridgeService,
  CookieService,
  ToastService,
  CommonService,
  ReportService,
  InterestsService,
  LocalStorageService,
  {
    provide: HttpConfig,
    useFactory(configService: ConfigService) {
      return {
        baseURL: configService.getRequestUrl,
        timeout: 5000
      }
    },
    deps: [ConfigService]
  }, {
    provide: ConfigService,
    useFactory(cookie: CookieService) {
      // 可牛压缩
      if (cookie.productId === String(product_id_info.ys)) {
        return new YsService()
      }

      throw new Error('请检查产品id是否正确')
    },
    deps: [CookieService]
  },
  {
    provide: HttpInterceptor,
    useClass: HttpInterceptorService
  }
]

const rootInjector = new ReflectiveInjector(new NullInjector(), providers)
const bridge = rootInjector.get(BridgeService)

bridge.getTid().finally(() => {
  createApp(App)
    .use(reflectiveInjectorPlugin, rootInjector)
    .use(router)
    .use(Toast)
    .mount('#app')
})
