import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'
import { BridgeService } from '@/services/bridge.service'
import {Subject} from 'rxjs';

interface InterestDescListPageDetail {
  /** 权益详情标题 */
  title: string
  /** 权益详情描述标题 */
  desc_list: any[]
  /** 权益详情图片url */
  img_url: string
  /** 权益详情按钮文字 */
  btn_text: string
   /** 客户端程序名称 */
   path: string
   /** 客户端参数 */
   cmd: string
}

interface InterestListPageDetail {
  /** 是否为超级会员 */
  is_super_member: boolean
}

export interface InterestList {
  /** 标题 */
  title: string
  /** 图片的url */
  img_url: string
  /** 客户端程序名称 */
  path: string
  /** 客户端参数 */
  cmd: string
  /** 是否支持启动（支持：true, 不支持:false  不支持启动则不会有权益加载进度条，name,arg,interface默认配置空字符串，checkInstall默认配置false） */
  is_start_up?: boolean
  /** 权益进度条状态 */
  progess_bar?: boolean
  /** 禁用系统 */
  disable_os: string[]
  /** 对应”权益描述大卡片“的key */
  desc_key: string
  page_detail: InterestListPageDetail
}

export interface InterestDescLists {
  /** 唯一表示key */
  key: string
  page_detail: InterestDescListPageDetail
}

export interface AppInterestList {
  /** 所属的app_id */
  app_id: string
  /** 展示的名称 */
  name: string
  interest_list: InterestList[]
  interest_desc_list: InterestDescLists[]
}

export interface InterestParams {
  os: string /** 操作系统版本 */
}

export interface InterestResponseData {
  /** 产品会员权益 */
  app_interest_list: AppInterestList[] /** 可牛超级会员权益 */
}

@Injectable()
export class InterestsService {
  onInterestSizeChanged = new Subject<AppInterestList[]>() // 权益数量传递
  /** 权益信息集合 */
  interestInfo = {}

  constructor(
    private http: Http,
    private bridge: BridgeService
  ) {
  }

  /**
   * @description 请求设备信息
   */
  requestDeviceInfo() {
    return this.bridge.getDeviceInfo()
  }

  /**
   * @description 请求权益列表（根据产品区分不同权益）
   */
  requestInterest(params: InterestParams) {
    return this.http.post<InterestResponseData>('/interest/show', params).then(res => {
      this.onInterestSizeChanged.next(res.app_interest_list)
      return res
    })
  }
}
