import {Injectable} from '@tanbo/vue-di-plugin'

@Injectable()
export class LocalStorageService {
  get couponShowTimestamp ():string {
    return localStorage.getItem('_vip_coupon') || ''
  }

  set couponShowTimestamp (timestamp) {
    localStorage.setItem('_vip_coupon', `${timestamp}`)
  }
}
