import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/mc',
    name: 'MemberCenter',
    component: () => import('@/views/mobile/MemberCenter/index.vue')
  },
  {
    path: '/cmc',
    name: 'CouponMemberCenter',
    component: () => import('@/views/mobile/MemberCenter/CouponMemberCenter.vue')
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('@/views/exchange/index.vue')
  },
  {
    path: '/pay-point',
    name: 'PayPoint',
    component: () => import(/* webpackChunkName: "pay-point" */ '@/views/custom-made/pay-point/index.vue')
  },
  {
    path: '/pay-point-status',
    name: 'PayPointStatus',
    component: () => import(/* webpackChunkName: "pay-point-status" */ '@/views/custom-made/pay-point/_components/pay-point-status.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
