import {Injectable} from '@tanbo/vue-di-plugin';
import { formatDate } from '@shared/utils/base'

@Injectable()
export class CommonService {
  /**
   * @description 日期格式化
   */
  handleDate (stamp: number, fmt = 'yyyy-MM-dd') {
    if (!stamp) return

    return formatDate(new Date(stamp), fmt)
  }
}
