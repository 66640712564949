import cookie from 'js-cookie'
import {Injectable} from '@tanbo/vue-di-plugin'
import {longUserInfoParams} from '@/services/user.service'

export interface recentLoginList {
  /** 性别 */
  sex: number,
  /** 姓名 */
  name: string,
  /** 头像 */
  avatar?: string,
  /** 用户uid */
  open_id: string,
  /** 登录类型 */
  login_type?: number,
  /** token */
  token?:string
}

@Injectable()
export class CookieService {
  /** 客户端版本 */
  get vipVersion ():string {
    return cookie.get('vip_version') || '0'
  }

  /** 服务端唯一id */
  get uuid ():string {
    return cookie.get('uuid') ?? ''
  }

  /** 客户端唯一id */
  get svrid ():string {
    return cookie.get('svrid') ?? ''
  }

  /** 客户端来源 */
  get show_from ():string {
    return cookie.get('show_from') || '0'
  }

  /** 客户端来源 */
  set show_from (show_from:string) {
    cookie.set('show_from', show_from)
  }

  /** 权益来源 */
  get fun_from():string {
    return cookie.get('fun_from') ?? ''
  }

  /** 另一种权益来源 */
  get fun_from1():string {
    return cookie.get('fun_from1') ?? ''
  }

  /** 渠道号 */
  get tryno(): string {
    return cookie.get('tryno') ?? ''
  }

  /** 产品id */
  get productId(): string {
    return '108'
  }

  /** 是否登录 */
  get isLogin(): boolean {
    return cookie.get('LS') === '1'
  }

  /** 用户标识 */
  get getToken (): string {
    return cookie.get('AT') ?? ''
  }

  /** 设置用户标识 */
  setToken (token: string) {
    cookie.set('AT', token, { expires: 90 })
  }

  /** 删除唯一用户标识 */
  removeToken () {
    cookie.remove('AT')
  }

  /** 唯一令牌 */
  get getOpenId (): string {
    return cookie.get('OI') ?? ''
  }

  /** 获取安装时间 */
  get installTime (): string {
    return cookie.get('install_time') ?? ''
  }

  /** 设置唯一令牌 */
  setOpenId (openId: string) {
    cookie.set('OI', openId, { expires: 90 })
  }

  /** 删除唯一令牌 */
  removeOpenId () {
    cookie.remove('OI')
  }

  /** 获取用户信息 */
  getUserInfo () {
    try {
      const userinfo = cookie.get('UI')
      return JSON.parse(userinfo)
    } catch {
      return {}
    }
  }

  /** 设置用户信息 */
  setUserInfo (userInfo) {
    cookie.set('UI', JSON.stringify(userInfo), { expires: 90 })
  }

  /** 删除用户信息 */
  removeUserInfo() {
    cookie.remove('UI')
  }

  /** 获取最近登录列表 */
  getRecentLoginList () {
    try {
      const value = cookie.get('recent_login_list')
      return JSON.parse(value)
    } catch {
      return []
    }
  }

  /** 增加最近登录历史 */
  addRecentLoginList (info) {
    const list = this.getRecentLoginList()

    const idx = list.findIndex(e => e.open_id === info.open_id)
    if (idx > -1) {
      list.splice(idx, 1)
    }

    list.unshift(info)
    this.setRecentLoginList(list)
  }

  /** 设置最近登录列表 */
  setRecentLoginList (list: recentLoginList[]) {
    cookie.set('recent_login_list', list, { expires: 90 })
  }

  /** 删除最近登录某一项 */
  removeRecentLoginItem (index: number) {
    const list = this.getRecentLoginList()
    if (list) {
      list.splice(index, 1)
      this.setRecentLoginList(list)
    }
  }

  /**
   * @description 保存持久化用户信息集合
   */
  setUserInfoLongTerm (params: longUserInfoParams) {
    const { token, open_id, userInfo } = params
    // 很多请求依赖token, open_id，为了方便使用在这里单独存储
    this.setToken(token)
    this.setOpenId(open_id)
    userInfo && this.setUserInfo(userInfo)
  }

  /**
   * @description 删除持久化用户信息集合
   */
  removeUserInfoLongTerm () {
    this.removeToken()
    this.removeOpenId()
    this.removeUserInfo()
  }
}
