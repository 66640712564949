
import {ref, watch} from 'vue';
export default {
  name: 'MyToast',
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const message = ref('');
    watch(() => props.message, newVal => {
      message.value = newVal;
    })
    return {
      // eslint-disable-next-line vue/no-dupe-keys
      message
    }
  }
}
