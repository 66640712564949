/* eslint-disable no-magic-numbers */
import isDate from 'lodash/isDate'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import { isDef } from './function'

const NUM_ONE = 1

/**
 * @description 处理时间戳
 * @param {number} num | 时间戳
 * @returns {Date}
 */
const handleTimeStamp = num => {
  const MAX_SIZE = 10

  if (String(num).length === MAX_SIZE) {
    const turn = 1000
    num = num * turn
  }

  return new Date(num)
}

/**
 * 格式化日期对象
 * @function
 * @param {Date|string|number} date
 * @param {string} fmt
 * @returns {string}
 * @example
 * dateFormat(new Date('2014/02/03 04:05:06'),'yyyy-MM-dd hh:mm:ss')
 * dateFormat(new Date(), 'yyyy-M-d h:m:s.S')
 * dateFormat(new Date(1605510250499), 'yyyy-M-d h:m:s.S')
 */
/* eslint-disable max-lines-per-function */
export const formatDate = (date, fmt) => {
  if (isString(date)) {
    // fix: 解决IE、firefox浏览器下JS的new Date('2020-11-02 15:06:30')的值为Invalid Date、NaN-NaN的问题
    date = new Date(/** @type {string} */(date).replace(/-/g, '/'))
  } else if (isNumber(date)) {
    date = handleTimeStamp(/** @type {number} */(date))
  }

  if (!isDate(date)) { return '' }

  // fix:tsdoc生成文档报错问题
  // eslint-disable-next-line no-self-assign
  date = /** @type {Date} */(date)

  if (isNaN(date.getTime())) { return '' }

  if (!fmt) {
    fmt = 'yyyy-MM-dd'
  }

  // 一个季是3个月
  const SEASON_HAS_MONTH_COUNT = 3
  const month = date.getMonth()
  const o = {
    'M+': month + NUM_ONE, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((month + SEASON_HAS_MONTH_COUNT) / SEASON_HAS_MONTH_COUNT), // 季度
    S: date.getMilliseconds() // 毫秒
  }

  if (/(y+)/.test(fmt)) {
    const yearStr = `${date.getFullYear()}`
    const DEFAULT_YEAR_LEN = 4
    fmt = fmt.replace(RegExp.$1, yearStr.substr(DEFAULT_YEAR_LEN - RegExp.$1.length))
  }

  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let val
      if (RegExp.$1.length === NUM_ONE) {
        val = o[k]
      } else {
        val = (`00${o[k]}`).substr(`${o[k]}`.length)
      }
      fmt = fmt.replace(RegExp.$1, val)
    }
  }
  return fmt
}
/* eslint-enable max-lines-per-function */

/**
 * 获取指定日期的最后一秒，默认不传是当天的最后一秒
 * @param {Date=} date 日期
 */
export const getLastSecondOfDay = (date) => {
  if (!isDef(date)) {
    date = new Date()
  }
  if (!isDate(date)) {
    throw new Error('date needs to be of type date')
  }

  const day = formatDate(date, 'yyyy/MM/dd')
  return new Date(`${day} 23:59:59`)
}

/**
 * 计算指定时间之间的时间差
 * @param {Date} startTime 开始时间
 * @param {Date} [endTime=new Date()] 结束时间，默认值为当前时间
 * @returns {{days: number, hours: number, minutes: number, seconds: number}}  时间差
 */
export const countTimeDifference = (startTime, endTime = new Date()) => {
  if (!isDate(startTime) || !isDate(endTime)) {
    throw new Error('date needs to be of type date')
  }
  const time = Math.floor((endTime.getTime() - startTime.getTime()) / 1000)
  const days = Math.floor(time / 60 / 60 / 24)
  const hours = Math.floor(time / 60 / 60 % 24)
  const minutes = Math.floor(time / 60 % 60)
  const seconds = Math.floor(time % 60)

  return { days, hours, minutes, seconds }
}
