import { getUrlParam } from '@shared/utils/base'
import { ConfigService, product_id_info } from '@/services/config.service';

export class YsService extends ConfigService {
  /** 获取区分场景套餐id */
  get scenePackageId () {
    const sceneName = getUrlParam('scene')
    const scene_info = {
      home: '001',
      wxmc: '010',
    }

    return scene_info[sceneName] ?? '001'
  }

  getTryno= '5600'
  getTitle= '可牛压缩'
  wxAppId= 'wx701b6bf77994b435'
  getPackageId= product_id_info.ys + this.scenePackageId
  getScenePackageId= this.scenePackageId
  getLogo= require('@/assets/images/ys/logo.png')
  getOfficialLoginImg= require('@/assets/images/ys/guide_bg.png')
  getHeaderQrCodeImg= require('@/assets/images/ys/qrcode_official.png')
  getMemberProtocolLink= 'https://ys.keniu.com/keniukzipVipservice.html'

  getProductInfo= {
    appid: '108',
    secret: 'x00unnidxaeldx10kpnye2idzxa026ap',
    version: 1
  }

  getMemberTypeInfo= {
    normal: 'knzip_normal',
    diamond: 'knzip_diamond'
  }

  getRequestUrl = 'https://api-zipvip.keniu.com'
}
