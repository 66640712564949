import { getCurrentInstance } from 'vue';
import {Injectable} from '@tanbo/vue-di-plugin';
import {toastParams} from '@/components/toast/toast';

@Injectable()
export class ToastService {
  private proxy:any
  constructor() {
    const currentVue = getCurrentInstance()
    this.proxy = currentVue.proxy
  }

  /** 展示toast */
  showToast (params: toastParams) {
    this.proxy.$toast({
      message: params.message,
      duration: params.duration,
      onClose: params.onClose
    })
  }
}
