<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  user-select: none;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none;  /*webkit浏览器*/
  -ms-user-select: none;   /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
}
</style>
