import { Injectable } from '@tanbo/vue-di-plugin'
import * as bridge from '@vipsdk/js-bridge'
import { ConfigService } from '@/services/config.service';

export interface vipVerionInfo {
  data: {
    retust: number /** 客户端版本号 */
  }
}

export interface KisProcessParams {
  path?: string /** 路径 */
  cmd?: string /** 命令参数 */
}

export interface AwakenAppParams {
  softname?: string /** 软件名称 */
  cmd?: string /** 调起参数，用于打开软件后调起对应的功能页面 */
}

export interface TidInfo {
  tid1: number
  tid2: number
  tod1: number
  tod2: number
  subpid: number
  fid: number
}

export interface WincloseParams {
  /**  登录成功返回的用户token */
  token: string,
  /**  登录成功返回的用户open_id, */
  open_id: string,
  /**  登录方式；1:微信, 2:QQ, 3:手机登录 */
  type: number,
  /**  是否刷新界面 默认0 刷新 */
  noRefresh?:number,
}

export interface RefreshUserInfoParams {
  /**  用户token */
  token: string,
  /**  用户open_id */
  open_id: string,
}

export interface DeviceInfoResponseData {
  /** 名称？ */
  name: string
  /** 系统名称 */
  os: string
  /** 结果码 */
  result: number
}

export interface isAppInstallParams {
  /** 应用名称 */
  softname: string
}

interface OpenOptionWindowParams {
  url: string
  /** 页面标识 */
  open_web: string
  width: number
  height: number
}

interface RefreshPageParams {
  /** 类别 include=需要, ignore=不需要 */
  type: string
  /** 与客户端协定的页面名称 */
  pages: string[]
}

@Injectable()
export class BridgeService {
  tidInfo = {
    tid1: 0,
    tid2: 0,
    tod1: 0,
    tod2: 0,
    subpid: 0,
    fid: 0
  } as TidInfo

  /** 默认用户参数 */
  private defaultUserParams = {} as WincloseParams

  constructor(
    private config: ConfigService
  ) {
  }

  /** 设置默认用户参数 */
  setDefaultUserParams (params: WincloseParams) {
    this.defaultUserParams = params
  }

  /** 获取客户端公共参数，产品用于区分场景？ */
  async getTid () {
    const t = await bridge.callRequest({ fnName: 'getTid' })
    const f = await bridge.callRequest({ fnName: 'getScenesInfo' })

    this.tidInfo = { ...t, ...f }
  }

  /** 获取客户端版本 */
  getVipVersion (): Promise<vipVerionInfo> {
    return bridge.client_getVipVersion()
  }

  /** 打开闪电查杀等应用 */
  openKisProcess (params: KisProcessParams) {
    return bridge.client_openKisProcess(params)
  }

  /** 退出 */
  exit () {
    return bridge.client_logout()
  }

  /** 刷新页面 */
  refreshPage (params?: RefreshPageParams) {
    return bridge.client_refresh_pages(params)
  }

  /** 刷新用户 */
  refreshUserInfo () {
    const params = {
      open_id: this.defaultUserParams.open_id,
      token: this.defaultUserParams.token,
    } as RefreshUserInfoParams

    return bridge.client_refresh_userinfo(params)
  }

  /** 会员中心窗口登录 */
  userLogin () {
    // TODO:这里跳转链接是客户端控制的，可以传任意值，但不传打不开
    // 会员中心调用登录会走客户端main的一个类，在其他窗口调用进入的是另一个类。 其他窗口调用现象：没反应（客户端深坑呀）
    return bridge.client_open_login_window({ url: '/login' })
  }

  /** 其他窗口登录, 当前登录为其他窗口调不起userLogin而生 */
  otherUserLogin () {
    const { origin, search } = location
    const params = {
      url: `${origin}/login${search}`,
      width: 350,
      height: 470
    }

    return bridge.client_open_login_window(params)
  }

  /** 获取设备的信息 系统名和版本 */
  getDeviceInfo (): Promise<DeviceInfoResponseData> {
    return bridge.client_get_device_info()
  }

  /** 客户端上报 */
  report (table: string, info: any) {
    return bridge.client_report_by_kfmt(table, info)
  }

  /** 通知客户端刷新页面 */
  winClose(params: WincloseParams = this.defaultUserParams) {
    return bridge.client_winclose(params)
  }

  /** 调用接口打开相应的前端界面窗口 */
  openOptionWindow(params: OpenOptionWindowParams) {
    return bridge.client_openOptionWindow(params)
  }

  /** 安装并调起应用 */
  awakenApp (params: AwakenAppParams) {
    return bridge.callRequest({ fnName: 'awakenApp', params })
  }

  /** 判断应用是否安装 */
  isAppInstall (params: isAppInstallParams) {
    return bridge.callRequest({ fnName: 'isAppInstall', params })
  }
}
