import { BridgeService } from '../services/bridge.service'
import {Injectable} from '@tanbo/vue-di-plugin'
import {CookieService} from '@/services/cookie.service';
import zhfeCollect from '@zhfe/zhfe-collect'

const pubilc_filed = {
  uuid: '',
  tid1: 0,
  tid2: 0,
  tod1: 0,
  tod2: 0,
  type: 0,
  tryno: 0,
  iid: 0,
  collect_time: 0,
  lastver: 0,
  svrid: '',
  wtod2: '',
  installtime: '',
  osver: '',
  unionsvrid: '',
  product_no: 557,
  public_index: 1
}

@Injectable()
export class ReportService {
  /** 埋点版本 */
  report_version = '2'

  constructor(
    private bridge: BridgeService,
    private cookie: CookieService
  ) {
  }

  webReportUrl = 'https://helpinfockeniu1.ksmobile.com/g/v1/'

  /**
   * 网页上报
   */
  webReport (baseObj = {}, dat = '') {
    return (data = {}) => {
      zhfeCollect(this.webReportUrl, { ...pubilc_filed, ...baseObj }, dat).report(data)
    }
  }

  /**
   * @description 上报方法
   */
  makeReporter<T>(table: string, defaultConfig: T) {
    return (customConfig: Partial<T> = {}): Promise<any> => {
      const info = this.merge(defaultConfig, customConfig)
      return this.bridge.report(table, info)
    }
  }

  /**
   * @description 合并参数
   */
  private merge(left: any, right: any) {
    // 公用上报参数放在这里
    const reoport_obj = {
      vip_version: String(this.cookie.vipVersion), /** 客户端版本 */
      version: this.report_version, /** 埋点版本 */
      product_id: this.cookie.productId /** 产品id */
    }

    Object.keys(left).forEach(key => {
      reoport_obj[key] = (right[key] || left[key]) + ''
    })

    return reoport_obj
  }
}
